head {
  position: relative;
  min-height: 100vh;
}

body {
  padding-top: 40px;
}

.registered {
  color: red;
  font-weight: 500;
}
